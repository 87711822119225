import { set, isEqual } from 'lodash'
import { fromJS } from 'immutable'

const defaultFilters = {
  excursions: {
    kinds: ['customized', 'root', 'ugozer', 'tourist_office'],
    measurement_system: 'metric',
    limit_to_a_radius: false,
    radius: null,
  },
  pointsOfInterest: {
    certified: 'all',
    measurement_system: 'metric',
    limit_to_a_radius: false,
    radius: null,
    tags_mode: 'all',
    creator_id: 'no',
    tags_ids: [],
    countries: [],
    order: 'distance',
    point_of_interest_category_id: null,
  },
  supportRequests: {},
  payments: {},
  interactions: {},
}

const state = {
  excursions: fromJS(defaultFilters.excursions).toJS(),
  pointsOfInterest: fromJS(defaultFilters.pointsOfInterest).toJS(),
  supportRequests: fromJS(defaultFilters.supportRequests).toJS(),
  payments: fromJS(defaultFilters.payments).toJS(),
  interactions: fromJS(defaultFilters.interactions).toJS(),
  default: defaultFilters,
}

const mutations = {
  SET_FILTER(state, payload) {
    set(state[payload.type], payload.name, payload.value)
  },

  SET_DEFAULT(state, payload) {
    set(state.default[payload.type], payload.name, payload.value)
  },

  RESET_FILTERS(state, type) {
    state[type] = fromJS(defaultFilters[type]).toJS()
  },
}

const actions = {
  updateDefaultFilters({ commit }, payload) {
    Object.entries(payload.values).forEach(([name, value]) => {
      commit('SET_DEFAULT', { type: payload.type, name, value })
    })

    // Sync default filter and the current one
    if (payload.refresh) {
      Object.entries(payload.values).forEach(([name, value]) => {
        commit('SET_FILTER', { type: payload.type, name, value })
      })
    }
  },
}

const getters = {
  getFiltersCount: (state) => (type) => {
    let count = 0
    Object.entries(state[type]).forEach(([name, value]) => {
      // Count how many filters are different from default ones
      if (!isEqual(value, state.default[type][name])) {
        count += 1
      }
    })

    return count
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
